import RMIT from "../assets/School/RMIT.jpg";
import Startblue from "../assets/Icon/Startblue.jpg";
import humanblue from "../assets/Icon/humanblue.jpg";

export const Top10 = [
  {
    Logo: "logo_url",
    name: "Trường Đại Học FPT",
    rating: "6507",
    top: "1",
  },
  {
    Logo: "logo_url",
    name: "Trường Đại Học Văn Lang",
    rating: "6507",
    top: "2",
  },
  {
    Logo: "logo_url",
    name: "Trường Đại Học Kinh Tế TP.HCM",
    rating: "6507",
    top: "3",
  },
  {
    Logo: "logo_url",
    name: "Trường Đại Học Bách Khoa TP.HCM",
    rating: "6507",
    top: "4",
  },
  {
    Logo: "logo_url",
    name: "Trường Đại Học Ngoại Thương",
    rating: "6507",
    top: "5",
  },
  {
    Logo: "logo_url",
    name: "Trường Đại Học Khoa Học Xã Hội và Nhân Văn",
    rating: "6507",
    top: "6",
  },
  {
    Logo: "logo_url",
    name: "Trường Đại Học Tôn Đức Thắng",
    rating: "6507",
    top: "7",
  },
  {
    Logo: "logo_url",
    name: "Trường Đại Học Xây Dựng",
    rating: "6507",
    top: "8",
  },
  {
    Logo: "logo_url",
    name: "Trường Đại Học Hồng Bàng",
    rating: "6507",
    top: "9",
  },
  {
    Logo: "logo_url",
    name: "Trường Đại Học Giao Thông Vận Tải",
    rating: "6507",
    top: "10",
  },
];

export const ListSchooklFake = [
  {
    stt: 1,
    image: RMIT,
    name: "Trường Đại Học FPT",
    level: "Trường Đại Học",
    province: "Hồ Chí Minh",
    quận: "Quận 9",
    user: 8854,
    follow: 1000,
    xa: "Phước Long B",
    banner: "",
    logo: "",
    content:
      "Trường Đại học FPT là một trong những cơ sở giáo dục hàng đầu tại Việt Nam, với sứ mệnh cung cấp môi trường học tập và nền tảng công nghệ tiên tiến cho sinh viên. Với hơn 20 năm hoạt động, trường đã xây dựng được uy tín vững chắc trong lĩnh vực đào tạo Công nghệ thông tin và các ngành liên quan",
    address:
      "Lô E2a-7, Đường D1 Khu Công nghệ cao, P. Long Thạnh Mỹ, TP. Thủ Đức, TP. Hồ Chí Minh",
    phone: "028 7300 1866",
    email: "daihocfpt@fpt.edu.vn",
    group: [
      {
        name: "Khoa Công nghệ thông tin - AI có gì vui với thế giới chúng ta",
        logo: "",
        content: "niêm khóa K23",
      },
    ],
    news: [
      {
        id: 1,
        user: "Hồ Lê Bảo Hân",
        content:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullam laoreet dolore magna aliquam and bneem ándnd erat volutpat...",
        like: 100,
        comment: 100,
        share: 100,
      },
      {
        id: 2,
        user: "Hồ Lê Bảo Hân",
        content:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullam laoreet dolore magna aliquam and bneem ándnd erat volutpat...",
        like: 100,
        comment: 100,
        share: 100,
      },
    ],
  },
  {
    stt: 2,
    image: RMIT,
    name: "Trường Đại Học Văn Lang",
    level: "Trường Đại Học",
    province: "Hồ Chí Minh",
    district: "Quận 9",
    user: 8854,
    follow: 1000,
    banner: "",
    logo: "",
    content: "",
    address: "",
    phone: "",
    email: "",
    group: [],
    news: [],
  },
  {
    stt: 3,
    image: RMIT,
    name: "Trường Đại Học Kinh Tế TP.HCM",
    level: "Trường Đại Học",
    province: "Hồ Chí Minh",
    district: "Quận 9",
    banner: "",
    logo: "",
    content: "",
    address: "",
    phone: "",
    email: "",
    group: [],
    news: [],
  },
  {
    stt: 4,
    image: RMIT,
    name: "Trường Đại Học Bách Khoa TP.HCM",
    level: "Trường Đại Học",
    province: "Hồ Chí Minh",
    district: "Quận 9",
    user: 8854,
    follow: 1000,
    banner: "",
    logo: "",
    content: "",
    address: "",
    phone: "",
    email: "",
    group: [],
    news: [],
  },
  {
    stt: 5,
    image: RMIT,
    name: "Trường Đại Học Ngoại Thương",
    level: "Trường Đại Học",
    province: "Hồ Chí Minh",
    district: "Quận 9",
    user: 8854,
    follow: 1000,
    banner: "",
    logo: "",
    content: "",
    address: "",
    phone: "",
    email: "",
    group: [],
    news: [],
  },
  {
    stt: 6,
    image: RMIT,
    name: "Trường Đại Học Khoa Học Xã Hội và Nhân Văn",
    level: "Trường Đại Học",
    province: "Hồ Chí Minh",
    district: "Quận 9",
    user: 8854,
    follow: 1000,
    banner: "",
    logo: "",
    content: "",
    address: "",
    phone: "",
    email: "",
    group: [],
    news: [],
  },
  {
    stt: 7,
    image: RMIT,
    name: "Trường Đại Học Tôn Đức Thắng",
    level: "Trường Đại Học",
    province: "Hồ Chí Minh",
    district: "Quận 9",
    user: 8854,
    follow: 1000,
    banner: "",
    logo: "",
    content: "",
    address: "",
    phone: "",
    email: "",
    group: [],
    news: [],
  },
  {
    stt: 8,
    image: RMIT,
    name: "Trường Đại Học Xây Dựng",
    level: "Trường Đại Học",
    province: "Hồ Chí Minh",
    district: "Quận 9",
    user: 8854,
    follow: 1000,
    banner: "",
    logo: "",
    content: "",
    address: "",
    phone: "",
    email: "",
    group: [],
    news: [],
  },
  {
    stt: 9,
    image: RMIT,
    name: "Trường Đại Học Hồng Bàng",
    level: "Trường Đại Học",
    province: "Hồ Chí Minh",
    district: "Quận 9",
    user: 8854,
    follow: 1000,
    banner: "",
    logo: "",
    content: "",
    address: "",
    phone: "",
    email: "",
    group: [],
    news: [],
  },
  {
    stt: 10,
    image: RMIT,
    name: "Trường Đại Học Giao Thông Vận Tải",
    level: "Trường Đại Học",
    province: "Hồ Chí Minh",
    district: "Quận 9",
    user: 8854,
    follow: 1000,
    banner: "",
    logo: "",
    content: "",
    address: "",
    phone: "",
    email: "",
    group: [],
    news: [],
  },
];

export const notification = [
  {
    image: Startblue,
    title: "PHIÊN BẢN 1.0.0 SẮP RA MẮT",
    content: "Phiên bản 1.0.0 sẽ ra mắt vào ngày 05/05/2024",
  },
];
